<template>
  <v-card
    class="fill-height"
  >
    <v-card-title
      class="pb-1"
    >
      {{ statisticTitle }}
      <v-spacer></v-spacer>
      <v-icon
        class="text--disabled"
      >
        mdi-help-circle-outline
      </v-icon>
    </v-card-title>
    <v-card-text
      class="fill-height"
    >
    <v-row>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ totalLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ totalValue }}
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ partLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ partValue }}
          </div>
        </v-col>
      </v-row>
      <vue-apex-charts
        :options="chartOptions"
        :series="seriesData"
        type="line"
        height="240"
      />
    </v-card-text>

  </v-card>
</template>


<script>
  import 'echarts/lib/chart/pie'

  export default{
    name: 'TendencyCardLine',

    components: {

    },

    props: {
      statisticTitle: {
        type: String,
        default: () => ''
      },
      totalValue: {
        type: Number,
        default: () => 0
      },
      partValue: {
        type: Number,
        default: () => 0
      },
      totalLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      partLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      totalRecords: {
        type: Array,
        default: () => []
      },
      warnRecords: {
        type: Array,
        default: () => []
      },
      chartDays: {
        type: Array,
        default: () => []
      },
      color: {
        type: String,
        default: '#FF1744',
      },
      colorTo: {
        type: String,
        default: '#EF9A9A'
      }
    },


    data() {
      return {
        chartOptions: {
            chart: {
                toolbar: { show: false },
                dropShadow: {
                  enabled: true,
                  top: 5,
                  left: 0,
                  blur: 4,
                  opacity: 0.10,
                },
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 8],
                width: [4, 2],
            },
            grid: {
                borderColor: '#e7e7e7',
            },
            legend: {
                show: false,
            },
            colors: ['#F97794', '#b8c2cc'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    inverseColors: false,
                    gradientToColors: ['#7367F0', '#b8c2cc'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                },
                axisTicks: {
                    show: false,
                },
                categories: this.chartDays,
                axisBorder: {
                    show: false,
                }
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    },
                    formatter: function(val) {
                        return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                    }
                }
            },
            tooltip: {
                x: { show: false }
            }
        }
      }
    },

    computed: {
      seriesData() {
        return [{
              name: this.totalLabel,
              data: this.totalRecords
          }, {
              name: this.partLabel,
              data: this.warnRecords
          }]
      }
    },

    methods: {

    },

    created() {

    }
  }
</script>
